/* eslint-disable class-methods-use-this */

export default class ModulesLoader {
  constructor() {
    this.libraries = {};

    // Lazy loading
    this.libraries.lazyLoading = {
      fileName: "lazy-loading",
      cssClassName: ".barba-container",
    };

    this.init();
  }

  init() {
    window.modules = [];
    const animations = window.checkIfModuleShouldBeLoaded(".animatable");
    const loadJS = window.checkIfModuleShouldBeLoaded(["[data-load-script]"]);

    // Load libraries
    window.loadJsLibraries(this.libraries);

    // Load scroll events that will responsible for loading modules and animations
    if (animations === true || loadJS === true) {
      import(/* webpackChunkName: "library-scrollmagic" */ "scrollmagic").then(
        ({ default: ScrollMagic }) => {
          if (loadJS === true) {
            this.loadModules(ScrollMagic);
          }
          if (animations === true) {
            this.loadAnimations(ScrollMagic);
          }
        }
      );
    }
  }

  loadAnimations(ScrollMagic) {
    window.setAnimationScenes = function setAnimationScenes(
      scrollMagicController
    ) {
      const animatableElements = [".animatable"];
      const header = document.getElementById("header");

      // Set animation classes to all the elements that will use them.
      /* eslint-disable no-restricted-syntax */
      for (const el of Array.from(
        document.querySelectorAll(animatableElements.join(","))
      )) {
        const containsInvertedLogoClass =
          el.classList.contains("js-invert-colors");

        window.animationsScrollMagic = new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 0.75,
          reverse: false,
        })
          .setClassToggle(el, "animated")
          .addTo(scrollMagicController);

        if (containsInvertedLogoClass === true) {
          window.headerScrollMagic = new ScrollMagic.Scene({
            triggerElement: el,
            offset: -45,
            triggerHook: 0,
            reverse: true,
          })
            .setClassToggle(header, "invert-colors")
            .addTo(scrollMagicController);
        }
      }
      /* eslint-enable no-restricted-syntax */
    };
    window.setAnimationScenes(new ScrollMagic.Controller());
  }

  loadModules(ScrollMagic) {
    window.setModulesLoadScenes = function setModulesLoadScenes(
      scrollMagicController
    ) {
      const loadJsElements = ["[data-load-script]"];
      // Set animation classes to all the elements that will use them.
      /* eslint-disable no-restricted-syntax */
      for (const el of Array.from(
        document.querySelectorAll(loadJsElements.join(","))
      )) {
        window.loadModulesScrollMagic = new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 0,
          offset: -window.innerHeight,
          reverse: false,
        })
          .addTo(scrollMagicController)
          .on("enter", (event) => {
            window.modulesLoader.loadComponentsAndSectionsModules(event);
          });
      }
      /* eslint-enable no-restricted-syntax */
    };
    window.setModulesLoadScenes(new ScrollMagic.Controller());
  }

  loadComponentsAndSectionsModules(event) {
    const element = event.target.triggerElement();
    const file = element.dataset.loadScript;

    if (element.tagName.toLowerCase() !== "section") {
      import(
        /* webpackChunkName: "component-[request]" */ `./template-parts/components/${file}`
      ).then(({ default: ObjectClass }) => {
        window.modules[`"${file}"`] = new ObjectClass(element);
      });
    } else {
      import(
        /* webpackChunkName: "section-[request]" */ `./template-parts/sections/${file}`
      ).then(({ default: ObjectClass }) => {
        window.modules[`"${file}"`] = new ObjectClass(element);
      });
    }
  }
}

import ScriptsLoaded from "./scripts-loaded";
import StylesLoaded from "./styles-loaded";

// Disable scroll
window.disableScroll = function disableScroll() {
  document.body.classList.add("u-block-scroll");
};

// Enable scroll
window.enableScroll = function enableScroll() {
  document.body.classList.remove("u-block-scroll");
};

// Ajax requests
window.ajaxReq = function ajaxReq() {
  if (window.XMLHttpRequest) {
    return new XMLHttpRequest(); // eslint-disable-line
  }

  if (window.ActiveXObject) {
    return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
  }

  console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
  return false;
};

// Check a CSS class to know if a module should be imported
window.checkIfModuleShouldBeLoaded = function checkIfModuleShouldBeLoaded(
  selectorToCheck
) {
  if (selectorToCheck !== "") {
    const module = document.querySelectorAll(selectorToCheck);

    if (module[0]) {
      return true;
    }
  }

  return false;
};

// Load JS scripts
window.loadJS = function loadJS(file, callback) {
  // DOM: Create the script element
  const jsElm = document.createElement("script");
  // set the type attribute
  jsElm.type = "application/javascript";
  // make the script element load file
  jsElm.src = file;
  // add a callback
  jsElm.addEventListener("load", callback);
  // finally insert the element to the body element in order to load the script
  document.body.appendChild(jsElm);
};

// Load CSS styles
window.loadCSS = function loadCSS(file, callback) {
  // DOM: Create the script element
  const jsElm = document.createElement("link");
  // set the rel attribute
  jsElm.rel = "stylesheet";
  // set the media attribute
  jsElm.media = "all";
  // make the script element load file
  jsElm.href = file;
  // add a callback
  jsElm.addEventListener("load", callback);
  // finally insert the element to the body element in order to load the script
  document.body.appendChild(jsElm);
};

// Load JS libraries
window.loadJsLibraries = function loadJsLibraries(libraries) {
  Object.entries(libraries).forEach(([key, value]) => {
    if (window.checkIfModuleShouldBeLoaded(value.cssClassName) === true) {
      import(/* webpackChunkName: "[request]" */ `./${value.fileName}`).then(
        ({ default: ObjectClass }) => {
          window.modules[`"${key}"`] = new ObjectClass(value.cssClassName);
        }
      );
    }
  });
};

// Scripts and styles html tags compatibility with BarbaJS
window.initializedScripts = ScriptsLoaded();
window.initializedStyles = StylesLoaded();

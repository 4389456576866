import "./scss/style.scss";
import "./js/lib/utils";
import Header from "./js/template-parts/common/header";
import Hero from "./js/template-parts/sections/hero";
import Cookies from "./js/template-parts/popups/cookies";
import Footer from "./js/template-parts/common/footer";
import ModulesLoader from "./js/modules-loader";

// Load objects
window.objectsLoaded = false;

document.addEventListener("DOMContentLoaded", () => {
  if (window.objectsLoaded === false) {
    // Header
    window.header = new Header();

    // Hero
    window.hero = Hero();

    // Cookies banner
    window.cookies = new Cookies();
  }
});

window.addEventListener("load", () => {
  if (window.objectsLoaded === false) {
    const libraries = {};

    // Footer
    window.footer = new Footer();

    // Modules loader
    try {
      window.modulesLoader = new ModulesLoader();
    } catch (error) {
      // code that changes the js body class to no-js
    }

    // BarbaJS
    libraries.barba = {
      fileName: "barba",
      cssClassName: ".barba-container",
    };

    // Load JS libraries
    window.loadJsLibraries(libraries);

    window.objectsLoaded = true;
  }
});

class Footer {
  constructor() {
    this.body = document.getElementsByTagName("body")[0]; // eslint-disable-line
    this.customCursorWrapper = document.querySelector(".cursor-wrapper");
    this.customCursor = document.querySelector(".cursor");

    this.init();
  }

  init() {
    if (
      this.customCursorWrapper &&
      this.customCursor &&
      typeof screen.orientation !== "undefined" // eslint-disable-line
    ) {
      setTimeout(() => {
        this.initCustomCursor();
      }, 100);
    }
  }

  initCustomCursor() {
    const buttons = document.querySelectorAll("a, button");
    const slides = document.querySelectorAll(".swiper-slide");

    // Remove native cursor
    this.body.style.cursor = "none";

    // Follow the mouse
    document.addEventListener("mousemove", (e) => {
      this.customCursor.setAttribute(
        "style",
        "top: " + (e.clientY - 20) + "px; left: " + (e.clientX - 20) + "px;" // eslint-disable-line
      );
    });

    // Expand cursor when mouse is hover an anchor
    buttons.forEach((button) => {
      button.addEventListener("mouseover", () => {
        this.customCursor.classList.add("expand");
      });
      button.addEventListener("mouseout", () => {
        this.customCursor.classList.remove("expand");
      });
    });

    // Expand cursor when mouse is hover a swiper slide and change cursor text when mouse is hover a slide anchor
    slides.forEach((slide) => {
      const slideAnchor = slide.getElementsByClassName(
        "slider-gallery__button"
      )[0];

      slide.addEventListener("mouseover", () => {
        this.customCursor.classList.add("slide");
      });
      slide.addEventListener("mouseout", () => {
        this.customCursor.classList.remove("slide");
      });

      slideAnchor.addEventListener("mouseover", () => {
        this.customCursor.classList.add("click");
      });
      slideAnchor.addEventListener("mouseout", () => {
        this.customCursor.classList.remove("click");
      });
    });
  }
}

export default Footer;

const StylesLoaded = () => {
  const initializedStyles = [];
  const stylesTags = document.querySelectorAll('link[rel^="stylesheet"]');

  Array.from(stylesTags).forEach((s) => {
    const href = s.getAttribute("href");
    if (href) {
      initializedStyles.push(href);
    }
  });

  return initializedStyles;
};

export default StylesLoaded;

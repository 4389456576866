const ScriptsLoaded = () => {
  const initializedScripts = [];
  const scriptTags = document.getElementsByTagName("script");

  Array.from(scriptTags).forEach((s) => {
    const src = s.getAttribute("src");
    if (src) {
      initializedScripts.push(src);
    }
  });

  return initializedScripts;
};

export default ScriptsLoaded;

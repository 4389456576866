/* eslint-disable class-methods-use-this, prefer-destructuring, no-use-before-define, no-shadow */
class Header {
  constructor() {
    this.initHeader();
  }

  initHeader() {
    const header = document.getElementById("header");
    const logo = document.getElementsByClassName("header__logo")[0];
    const mobileMenuToogler = document.getElementById("main-menu-toggler");
    const menuItems = document.querySelectorAll(".menu-item");
    let distanceToTop = document.documentElement.scrollTop;

    // Menu items event listener
    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", menuItemScrollToggle);
    });

    // Menu button event listener
    if (mobileMenuToogler) {
      mobileMenuToogler.addEventListener("click", windowScrollToggle);
    }

    // Scroll event listener
    window.addEventListener("scroll", throttle(callback, 200));

    if (distanceToTop > 0) {
      header.classList.add("scrolled");
    }

    // Window scroll toggle
    function windowScrollToggle() {
      const body = document.body;

      if (body.classList.contains("u-mobile-block-scroll")) {
        body.classList.remove("u-mobile-block-scroll");
      } else {
        body.classList.add("u-mobile-block-scroll");
      }
    }

    // Menu item window scroll toggle
    function menuItemScrollToggle() {
      if (!this.classList.contains("menu-item-has-children")) {
        windowScrollToggle();
      }

      mobileMenuToogler.checked = false;
    }

    function throttle(callback, wait = 100) {
      let timer = null;

      return (args) => {
        if (timer === null) {
          timer = setTimeout(() => {
            callback.apply(this, args);
            timer = null;
          }, wait);
        }
      };
    }

    function callback() {
      const newDistanceToTop = document.documentElement.scrollTop;

      if (newDistanceToTop > 500) {
        // logo.style.display = "initial";
        logo.classList.add("header__logo--animated");
      }

      if (newDistanceToTop < 500) {
        // logo.style.display = "none";
        logo.classList.remove("header__logo--animated");
      }

      distanceToTop = newDistanceToTop;
    }

    // Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
    // You can use it with the vh() SASS function.
    function setCSSvh() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    }

    window.addEventListener("resize", setCSSvh);
    window.addEventListener("orientationchange", setCSSvh);
    setCSSvh();
  }
}

export default Header;

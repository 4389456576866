const Hero = () => {
  const hero = document.getElementsByClassName("hero")[0];
  const video = hero.getElementsByClassName("hero__video")[0];

  function disableVideoOnLowBatteryMode() {
    // Prevent video play button from showing on low battery mode
    video.play().catch((error) => {
      if (error.name === "NotAllowedError") {
        // Make a copy of the video element
        const videoCopy = video.cloneNode(true);
        const videoSource = videoCopy.getElementsByTagName("source")[0];

        // Make the necessary changes before reatching the video element
        videoCopy.removeAttribute("autoplay");
        videoSource.removeAttribute("src");

        // Replace the original video with the modified video copy
        video.parentNode.replaceChild(videoCopy, video);
      }
    });
    video.load();
  }

  if (hero && video) {
    disableVideoOnLowBatteryMode();
  }
};

export default Hero;

/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/

class Cookies {
  constructor() {
    /* eslint-disable */
    Promise.all([
      import(
        /* webpackChunkName: "consentUI" */ "../../lib/cookies/consent-ui"
      ),
      import(
        /* webpackChunkName: "consentManager" */ "../../lib/cookies/consent-manager"
      ),
      import(/* webpackChunkName: "gtag" */ "tartemeringuee/services/gtag"),
    ])
      .then(
        ([
          { default: consentUI },
          { default: consentManager },
          { default: gtag },
        ]) => {
          const consent = consentManager();
          if (
            typeof googleAnalyticsId !== "undefined" &&
            googleAnalyticsId !== ""
          ) {
            consent
              .register(gtag(googleAnalyticsId))
              .setUI(
                consentUI({
                  getText: getText(),
                })
              )
              .launch();
          }
        }
      )
      .catch((err) => console.log(err));
  }
}

export default Cookies;
